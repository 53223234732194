import { lazy } from 'react'

import { List, Number, Slot, Style } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() => import('./Carousel').then((module) => ({ default: module.Carousel }))),
	{
		type: 'carousel',
		label: 'Carousel',
		props: {
			children: List({
				label: 'Slides',
				type: Slot(),
				getItemLabel(slide) {
					return `Slot`
				}
			}),
			className: Style(),
			itemsShown: Number({ label: 'Items shown (desktop)', defaultValue: 4, selectAll: true }),
			skip: Number({ label: 'Desktop arrows move by', defaultValue: 4, selectAll: true }),
			autoplay: Number({
				label: 'Autoplay',
				defaultValue: 0,
				step: 0.1,
				suffix: 's',
				selectAll: true
			})
		}
	}
)

import { lazy } from 'react'

import { Grid } from '@makeswift/prop-controllers'
import { MakeswiftComponentType } from '@makeswift/runtime/components'
import { List, Select, Shape, TextInput } from '@makeswift/runtime/controls'

import { DEFAULT_LOCALE, Locales } from '@/lib/constants'
import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() => import('./Root').then((module) => ({ default: module.Root }))),
	{
		type: MakeswiftComponentType.Root,
		label: 'Root',
		hidden: true,
		props: {
			children: Grid(),
			activeLocale: Select({
				label: 'Locale',
				options: Object.entries(Locales).map(([label, value]) => ({ label, value })),
				defaultValue: DEFAULT_LOCALE
			}),
			breadcrumbStructuredData: List({
				label: 'Breadcrumb structured data list',
				type: Shape({
					type: {
						name: TextInput({ label: 'Name' }),
						url: TextInput({ label: 'URL' })
					}
				}),
				getItemLabel(breadcrumb) {
					return breadcrumb?.name || 'Untitled'
				}
			}),
			bcReferralCookie: TextInput({
				label: 'bc_ref Cookie',
				defaultValue: ''
			})
		}
	}
)

import { lazy } from 'react'

import { Number, Slot, Style, TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

import { FolderStructure } from '@/utils/makeswift/constants'

export const props = {
	className: Style({
		properties: [Style.Margin, Style.Padding, Style.Border, Style.BorderRadius]
	}),
	animationId: TextInput({ label: 'Animation ID', selectAll: true }),
	desktopWidth: Number({ label: 'Desktop Width (px)', defaultValue: 400, selectAll: true }),
	desktopHeight: Number({ label: 'Desktop Height (px)', defaultValue: 100, selectAll: true }),
	mobileWidth: Number({ label: 'Mobile Width (px)', selectAll: true }),
	mobileHeight: Number({ label: 'Mobile Height (px)', selectAll: true }),
	children: Slot()
}

runtime.registerComponent(
	lazy(() => import('./UnicornAnimation').then((module) => ({ default: module.UnicornAnimation }))),
	{
		type: 'UnicornAnimation',
		label: `${FolderStructure.UnderDevelopment}/Unicorn Animation`,
		props
	}
)

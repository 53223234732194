import { lazy } from 'react'

import {
	List,
	Number,
	Select,
	Shape,
	Slot,
	Style,
	TextArea,
	TextInput
} from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

export const props = {
	className: Style({ properties: Style.All }),
	accordions: List({
		label: 'Accordions',
		type: Shape({
			type: {
				title: TextInput({
					label: 'Title',
					defaultValue: 'This is an accordion title',
					selectAll: true
				}),
				children: Slot(),
				answer: TextArea({
					label: 'FAQ answer (only shown in structured data)',
					defaultValue: '<p>This is an answer to this question</p>',
					selectAll: true
				})
			}
		}),
		getItemLabel(accordionItem) {
			return accordionItem?.title || 'This is an accordion title'
		}
	}),
	shownItems: Number({ label: 'Shown items', defaultValue: 3, selectAll: true }),
	variant: Select({
		label: 'Appearance',
		options: [
			{ value: 'light', label: 'Light mode' },
			{ value: 'dark', label: 'Dark mode' }
		],
		defaultValue: 'light'
	}),
	viewMore: TextInput({
		label: 'View More Text',
		defaultValue: 'View More',
		selectAll: true
	}),
	viewLess: TextInput({
		label: 'View Less Text',
		defaultValue: 'View Less',
		selectAll: true
	})
}

runtime.registerComponent(
	lazy(() => import('./Accordions').then((module) => ({ default: module.Accordions }))),
	{
		type: 'accordions',
		label: 'Accordions',
		props
	}
)

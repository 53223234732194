import { lazy } from 'react'

import { List, Shape, Slot, Style, TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() => import('./TabsCarousel').then((module) => ({ default: module.TabsCarousel }))),
	{
		type: 'tabs-carousel',
		label: 'Tabs Carousel',
		props: {
			className: Style(),
			tabs: List({
				label: 'Tabs',
				type: Shape({
					type: {
						label: TextInput({ label: 'Tab label' }),
						tab: Slot(),
						content: Slot()
					}
				}),
				getItemLabel(tab) {
					return tab?.label || 'Untitled'
				}
			})
		}
	}
)

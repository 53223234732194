import { lazy } from 'react'

import {
	Checkbox,
	Image,
	Link,
	List,
	Number,
	Select,
	Shape,
	Style,
	TextArea,
	TextInput
} from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() => import('./LogoCarousel').then((module) => ({ default: module.LogoCarousel }))),
	{
		type: 'logo-carousel',
		label: 'Logo Carousel',
		props: {
			slides: List({
				label: 'Slides',
				type: Shape({
					type: {
						logo: Image({
							label: 'Logo',
							format: Image.Format.WithDimensions
						}),
						logoAlt: TextInput({
							label: 'Logo alt text',
							defaultValue: 'Logo',
							selectAll: true
						}),
						quote: TextArea({
							label: 'Quote',
							defaultValue:
								'“Lorem ipsum dolor sit amet, ne has magna illud, graece invidunt ne est, doctus hendrerit accommodare vim ea. Harum detracto facilisi at mea. Eu sumo affert bonorum sea, pro an quando vocibus, te sed dolore ornatus.”',
							selectAll: true
						}),
						authorName: TextInput({
							label: 'Author name',
							defaultValue: 'John Smith',
							selectAll: true
						}),
						authorTitle: TextInput({
							label: 'Author title',
							defaultValue: 'Title',
							selectAll: true
						}),
						linkText: TextInput({
							label: 'Link text',
							defaultValue: 'Read more',
							selectAll: true
						}),
						link: Link({
							label: 'Link'
						}),
						mainImage: Image({
							label: 'Image',
							format: Image.Format.WithDimensions
						}),
						mainImageAlt: TextInput({
							label: 'Image alt text',
							defaultValue: 'Image',
							selectAll: true
						}),
						priorityMainImage: Checkbox({
							label: 'Priority Image?',
							defaultValue: false
						})
					}
				}),
				getItemLabel(slide) {
					return slide?.authorName || 'John Smith'
				}
			}),
			className: Style(),
			autoplay: Number({ label: 'Autoplay', defaultValue: 0, step: 0.1, suffix: 's' }),
			variant: Select({
				label: 'Appearance',
				options: [
					{ value: 'light', label: 'Light mode' },
					{ value: 'dark', label: 'Dark mode' }
				],
				defaultValue: 'light'
			})
		}
	}
)

import { lazy } from 'react'

import {
	Checkbox,
	Image,
	Link,
	List,
	Number,
	Shape,
	Slot,
	TextInput
} from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() =>
		import('./MegaMenuNavigation').then((module) => ({ default: module.MegaMenuNavigation }))
	),
	{
		type: 'mega-menu-navigation',
		label: 'Primary Navigation',
		props: {
			navigationTabs: List({
				label: 'Navigation tabs',
				type: Shape({
					type: {
						text: TextInput({ label: 'Text', defaultValue: 'Link', selectAll: true }),
						link: Link({ label: 'On click' }),
						active: Checkbox({ label: 'Active' })
					}
				}),
				getItemLabel(topNavLink) {
					return topNavLink?.text || 'Link'
				}
			}),

			topNavLinks: List({
				label: 'Top navigation',
				type: Shape({
					type: {
						text: TextInput({ label: 'Text', defaultValue: 'Link', selectAll: true }),
						link: Link({ label: 'On click' })
					}
				}),
				getItemLabel(topNavLink) {
					return topNavLink?.text || 'Link'
				}
			}),
			logoImage: Image({
				label: 'Desktop logo',
				format: Image.Format.WithDimensions
			}),
			logoWidth: Number({
				label: 'Desktop logo width',
				defaultValue: 132,
				suffix: 'px',
				selectAll: true
			}),
			mobileLogoImage: Image({
				label: 'Mobile logo',
				format: Image.Format.WithDimensions
			}),
			mobileLogoWidth: Number({
				label: 'Mobile logo width',
				defaultValue: 32,
				suffix: 'px',
				selectAll: true
			}),
			logoAlt: TextInput({
				label: 'Logo alt text',
				defaultValue: 'BigCommerce logo',
				selectAll: true
			}),
			logoLink: Link({ label: 'Logo on click' }),
			mainNavLinks: List({
				label: 'Main navigation',
				type: Shape({
					type: {
						text: TextInput({ label: 'Text', defaultValue: 'Link', selectAll: true }),
						link: Link({ label: 'On click' }),
						megaMenuContent: Slot(),
						hasMegaMenu: Checkbox({ label: 'Add Dropdown', defaultValue: true })
					}
				}),
				getItemLabel(mainNavLink) {
					return mainNavLink?.text || 'Link'
				}
			}),
			ctaText: TextInput({ label: 'CTA text', defaultValue: 'Request a demo', selectAll: true }),
			ctaLink: Link({ label: 'CTA on click' })
		}
	}
)
